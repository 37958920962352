import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpHeaders, HttpRequest, JsonpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class AppJsonpInterceptor extends JsonpInterceptor {
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (req.method === 'JSONP') {
      req = req.clone({
        headers: new HttpHeaders(),
      });
    }

    return next.handle(req);
  }
}
